@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&display=swap');

body{
    margin:0;
}

a{
    text-decoration: none;
    color: inherit;
}

.index_container{
    font-family: 'Playfair Display', serif;
    user-select: none;
}

.index-background_container{
    margin: 0;
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/vincent-burkhead-LhlxYMfnTF0-unsplash.jpg');
    background-size: cover;
    position: relative;
    overflow: hidden;
}


.index-content_container{
    background-color: rgba(222, 210, 191, 0.925);
    position: relative;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.centered-content{
    display: flex;
    flex-direction: column;
    z-index:2
}

h1{
    font-size: 100px;
    color: #363636;
    margin: 0 auto;
}

h1 span{
    font-size: 140px;
    color: #8f6f33;
}

.h2_container{
    font-size: 50px;
    position: relative;
    margin: 0 auto;
    overflow:hidden;
    margin-top:20px
}
.h2_container:after{
    content:'';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #333;
    left:-680px;
    top:0;
    animation: comingfromlefttoright 6s ease-out 1s forwards;
}

h2{
    color: #8f6f33;
    margin: 0;
    position: relative;
    top: -101px;
    animation: comingfromtoptobottom 1s ease-out 3s forwards;
}

@-webkit-keyframes comingfromlefttoright {
    0% {
        left: -680px;
    }
    25% {
        left: 0px;
    }
    60% {
        left: 0px;
    }
    100% {
        left: 680px;
    }
  }

@keyframes comingfromlefttoright {
    0% {
        left: -680px;
    }
    25% {
        left: 0px;
    }
    60% {
        left: 0px;
    }
    100% {
        left: 680px;
    }

  }

@-webkit-keyframes comingfromtoptobottom {
    from {
        top: -101px;
    }
    to {
        top: 0px;
    }
  }

@keyframes comingfromtoptobottom {
    from {
        top: -101px;
    }
    to {
        top: 0px;
    }
  }

.paragraph_about{
    width: fit-content;
    margin: auto;
    font-size: 18px;
    color: #141414;
}

.full-color-delayed{
    background-color: rgba(222, 210, 191, 0.925);
    animation: colordelayed ease 1s forwards
}

.not-full-color-delayed{
    background-color: rgba(222, 210, 191);
    animation: colordelayed ease 1s forwards reverse
}

@keyframes colordelayed {
    from {
        background-color: rgba(222, 210, 191, 0.925);
    }
    to {
        background-color: rgba(222, 210, 191);
    }
}

.image-of-cat{
    position: absolute;
    top: 0;
    right: -260px;
    height: 130vh;
    mix-blend-mode: multiply;
}
.paragraph_about span {
    font-size: 1.2em;
}


@media screen and (max-width:1440px) {
    h1{
        font-size: 60px;
    }
    
    h1 span{
        font-size: 110px;
    }
}
@media screen and (max-width:900px) {
    h1{
        font-size: 60px;
        color: #363636;
        margin: 0 auto;

        display: flex;
        justify-content: center;
        flex-flow: wrap;
    }
    
    h1 span{
        font-size: 110px;
        color: #8f6f33;
        width: 100vw;
        display: flex;
        justify-content: center;
    }
    .h2_container{
        font-size: 25px;
        position: relative;
        margin: 0 auto;
        overflow:hidden;
        margin-top:20px
    }
}
@media screen and (max-width:450px) {
    h1{
        font-size: 40px;
    }
    
    h1 span{
        font-size: 70px;
    }
    .h2_container{
        font-size: 18px;
    }
    .paragraph_about {
        font-size: 14px;
        margin: 45px;
    }
}

