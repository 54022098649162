.code-title{
    animation: none;
    top:0
}

.projects_code-global-container{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: 1265px;
}

.projects_images {
    width: 45%;
    height: 550px;
    display: flex;
    justify-content: space-around;
    overflow: hidden;
}

.container-text {
    width: 45%;
}

.img-50{
    height: 50%;
}

.height400{
    height: 400px;
}

.height300{
    height: 300px;
}

.projects_code-container {
    display: flex;
    justify-content: space-around;
    margin: 50px 0 120px 0;
}

.container-reverse {
    flex-direction: row-reverse;
}