.card_container{
    display: flex;
    flex-direction: column;
    width: 600px;
    background: #131313;
    height: min-content;
    overflow: hidden;
    font-family: system-ui;
    border-radius: 10px;
    box-shadow: 0px 8px 20px -3px #00000046;
    margin: 15px 0;
    position: relative;
}
/* 
.card_dot{
    position: absolute;
    width: 10px;
    height: 50px;
    right: 7px;
    top: -14px;
    z-index: 10;
    transform: rotate(315deg);
}

.isAvailable{
    background-color: rgb(0 123 221);
}
.isNotAvailable{
    background-color: rgb(219, 0, 0);
}
 */
.card_image_cont {
    display: flex;
    overflow: hidden;
    width: 600px;
    filter: brightness(0.58);
    justify-content: center;
    align-items: center;
    transition: .2s all;
    max-height: 300px;
}
 
.card_image {
    width: 110%;
    transition: .2s all;
}

.card_title {
    position: relative;
    transition: .2s all;
    top: -170px;
    color: #ffffff;
    font-size: 25px;
    margin: 0;
    font-weight: 400;
}

.card_info_cont {
    padding: 10px 20px;
    height: 30px;
    transition: .2s all;
}

.card_content_text{
    position: relative;
    bottom: -400px;
    transition: .2s all;
    font-size: 14px;
    text-align: justify;
    line-height: 1.5;
    color: #e8e8e8;
}

.card_stacks {
    font-weight: 700;
    color: #ffffff;
    top: -45px;
    transition: .2s all;
    position: relative;
}

span.material-symbols-outlined {
    position: relative;
    top: 4px;
}

.card_container:hover .card_content_text {
    bottom: 0;
}

.card_container:hover .card_info_cont {
    height: 200px;
}

.card_container:hover .card_image_cont {
    filter: none;
}

.card_container:hover .card_image {
    width: 100%;
}

.card_container:hover .card_title {
    top: 0px;
    color: #f1f1f1;
}

.card_container:hover .card_stacks {
    top: 0px;
}

@media screen and (max-width:600px){
    .card_container:hover .card_info_cont {
        height: fit-content;
    }
    .card_container:hover .card_stacks {
        height: fit-content;
    }
    .card_container{
        width: 300px;
    }
    
    .card_image_cont {
        width: 300px;
    }
    .card_image_cont {
        max-height: 170px;
    }
    .card_stacks {
        top: -52px;
        font-size: 14px;
        display: flex;
        align-items: center;
        height: 41px;
    }
    .card_title{
        top: -93px;
        font-size: 23px;
    }
}