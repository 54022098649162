.menus_container{
    display: flex;
    justify-content: space-between;
    padding: 35px 50px;
    font-size: 26px;
    color: #363636;
    z-index: 2;
}

@media screen and (max-width:450px) {
    .menus_container{
        display: flex;
        justify-content: space-between;
        padding: 20px 15px;
        font-size: 20px;
    }
    
}