.index-background_container {
    overflow-y: scroll;
}

.images_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px;
    width: 100%;
}
.bloc_container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.images-projects{
    height: 600px;
    cursor: pointer;
}

.images-projects_container {
    overflow: hidden;
    width: 330px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
}

.bloc-selection {
    height: 65px;
    width: 200px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    background-size: cover;
    position: relative;
    cursor: pointer;
}
.bloc-selection_design{
    background-color: #acacac;
    border: 3px solid #3d3d3d;
    background-position: center;
}
.bloc-selection:before{
    content:'';
    width: 100%;
    height: inherit;
    position: absolute;
    left: 0;
    right: 0;
    backdrop-filter:blur(169px);
}
.bloc-selection_code{
    background-color: #1a1a1a;
    border: 3px solid #3d3d3d;
}
.bloc-selection p {
    z-index:2;
    font-size: 1.3em;
    color: #fff;
}


/* CAROUSEL */

.carousel {
    width: 100%;
    position: fixed;
    height: 100%;
    background: #00000063;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.carousel-image {
    height: 85vw;
    z-index: 3;
    max-height: 1000px;
}